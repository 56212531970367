// @ts-nocheck
export enum DRAWERS {
  ACCOUNT_DETAILS = 'account-drawer',
  JOURNAL_DETAILS = 'journal-drawer',
  EXPENSE_DETAILS = 'expense-drawer',
  BILL_DETAILS = 'bill-drawer',
  INVOICE_DETAILS = 'invoice-detail-drawer',
  RECEIPT_DETAILS = 'receipt-detail-drawer',
  PAYMENT_RECEIVED_DETAILS = 'payment-receive-detail-drawer',
  PAYMENT_MADE_DETAILS = 'payment-made-drawer',
  ESTIMATE_DETAILS = 'estimate-detail-drawer',
  ITEM_DETAILS = 'item-detail-drawer',
  CUSTOMER_DETAILS = 'customer-detail-drawer',
  VENDOR_DETAILS = 'vendor-detail-drawer',
  INVENTORY_ADJUSTMENT_DETAILS = 'inventory-adjustment-drawer',
  CASHFLOW_TRNASACTION_DETAILS = 'cashflow-transaction-drawer',
  QUICK_CREATE_CUSTOMER = 'quick-create-customer',
  QUICK_CREATE_ITEM = 'quick-create-item',
  QUICK_WRITE_VENDOR = 'quick-write-vendor',
  CREDIT_NOTE_DETAILS = 'credit-note-detail-drawer',
  VENDOR_CREDIT_DETAILS = 'vendor-credit-detail-drawer',
  REFUND_CREDIT_NOTE_DETAILS = 'refund-credit-detail-drawer',
  REFUND_VENDOR_CREDIT_DETAILS = 'refund-vendor-detail-drawer',
  WAREHOUSE_TRANSFER_DETAILS = 'warehouse-transfer-detail-drawer',
  TAX_RATE_DETAILS = 'tax-rate-detail-drawer',
  CATEGORIZE_TRANSACTION = 'categorize-transaction',
  CHANGE_SUBSCARIPTION_PLAN = 'change-subscription-plan',
  INVOICE_CUSTOMIZE = 'INVOICE_CUSTOMIZE',
  ESTIMATE_CUSTOMIZE = 'ESTIMATE_CUSTOMIZE',
  PAYMENT_RECEIPT_CUSTOMIZE = 'PAYMENT_RECEIPT_CUSTOMIZE',
  RECEIPT_CUSTOMIZE = 'RECEIPT_CUSTOMIZE',
  CREDIT_NOTE_CUSTOMIZE = 'CREDIT_NOTE_CUSTOMIZE',
  PAYMENT_RECEIVED_CUSTOMIZE = 'PAYMENT_RECEIVED_CUSTOMIZE',
  BRANDING_TEMPLATES = 'BRANDING_TEMPLATES',
  PAYMENT_INVOICE_PREVIEW = 'PAYMENT_INVOICE_PREVIEW',
  STRIPE_PAYMENT_INTEGRATION_EDIT = 'STRIPE_PAYMENT_INTEGRATION_EDIT',
  INVOICE_SEND_MAIL = 'INVOICE_SEND_MAIL'
}
